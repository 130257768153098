import PageNotFound from '../components/pageNotFound/pageNotFound';
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import useNextRouterHook from '../hooks/useNextRouterHook';
import NextHead from '../components/header/nextHead';

function Error404() {
    const routerTo = useNextRouterHook();
    return (
        <>
            <NextHead>
                <meta name="robots" content="noindex" />
            </NextHead>
            <Header routerTo={routerTo} />
            <PageNotFound routerTo={routerTo}/>
            <Footer />
        </>
    )
}

export default Error404;