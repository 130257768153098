import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppContext } from '../../context/state';

export default function PageNotFound(props = {}) {
    const context = useAppContext()
    const { t } = useTranslation();
    const [urlType, setUrlType] = useState('');

    useEffect(() => {
        const getUrlType = () => {
            if (context.sharedState.urlNotFound) {
                setUrlType(context.sharedState.urlNotFound);
            }
        }

        getUrlType();

    }, [context.sharedState.urlNotFound])

    let customMessage;

    if (urlType === 'category') {
        customMessage = (
            <>
                <p className='message'>{t('not_found.page.category')}</p>
                <button className='action-button' onClick={() => props.routerTo('/categorias')}>{t('not_found.actions.see_categories')}</button>
                <button className='action-button' onClick={() => history.back()}>{t('actions.back')}</button>
            </>
        )
    } else if (urlType === 'company') {
        customMessage = (
            <>
                <p className='message'>{t('not_found.page.company')}</p>
                <button className='action-button' onClick={() => props.routerTo('/empresas')}>{t('not_found.actions.see_companies')}</button>
                <button className='action-button' onClick={() => history.back()}>{t('actions.back')}</button>
            </>
        )
    } else {
        customMessage = (
            <>
                <p className='message'>{t('not_found.page.default')}</p>
                <button className='action-button' onClick={() => history.back()}>{t('actions.back')}</button>
            </>
        )
    }

    return (
        <section className='not-found'>
            <p className='error-404 top'>404</p>
            <p className='error-404 bottom'>ERROR</p>
            {customMessage}
        </section>
    )
}